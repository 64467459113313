var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{staticClass:"btn-wrapper"},[_c('CButton',{staticClass:"transparent",attrs:{"block":"","color":"secondary","data-test-id":"add-attribute-btn"},on:{"click":function($event){return _vm.$refs['modal-add'].open()}}},[_c('CIcon',{staticClass:"icon-left",attrs:{"name":"cil-plus"}}),_vm._v(" Add ")],1)],1),_c('CCol',[_c('form',{staticClass:"search-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSearch($event)}}},[_c('CInput',{attrs:{"data-test-id":"attribute-keys-search","type":"text","placeholder":"Search"},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('button',{staticClass:"btn-search",attrs:{"type":"submit"}},[_c('FontAwesomeIcon',{attrs:{"icon":['far', 'search']}})],1)]},proxy:true}]),model:{value:(_vm.queryParams.q),callback:function ($$v) {_vm.$set(_vm.queryParams, "q", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"queryParams.q"}})],1)])],1),_c('BaseTable',{staticClass:"table-custom",attrs:{"is-loading":_vm.list.isLoading,"fields":_vm.FIELDS,"items":_vm.attributeKeys,"pagination":{
			pages: _vm.list.meta.lastPage,
			activePage: _vm.list.meta.currentPage
		},"striped":""},on:{"onPaginationClick":_vm.handlePageChange},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"empty-table-element"},[_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("global.searchNotFound", { field: "attribute key" }))+" ")])])]},proxy:true},{key:"name",fn:function(ref){
		var item = ref.item;
return [_c('CRow',[_c('CCol',[_vm._v(_vm._s(item))])],1)]}},{key:"id",fn:function(ref){
		var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('CButton',{staticClass:"transparent p-0",on:{"click":function($event){$event.preventDefault();return _vm.openModalDelete(item)}}},[_c('CIcon',{staticClass:"icon-trash",attrs:{"name":"cil-trash"}})],1)],1)]}}])}),_c('ModalCreateAttribute',{ref:"modal-add",attrs:{"handle-create":_vm.createAttributeKey,"is-error-duplicate-name":_vm.create.isErrorDuplicateName,"title":"Add new attribute","field":"Name","type":"attribute key"},on:{"onSuccess":_vm.handleCreateSuccess,"onResetError":_vm.resetErrorCreateAttributeKey}}),_c('ModalRemoveAttribute',{ref:"modal-remove",attrs:{"handle-remove":_vm.deleteAttributeKey.bind(null,_vm.selectedKeyId),"title":"Remove this attribute?","description":"Removing this attribute will also remove it and all its values from all products."},on:{"onSuccess":_vm.handleDeleteSuccess}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }